import React, { useState } from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import priceImg from '../../../assets/images/feature/price_img.png'
import priceImg1 from '../../../assets/images/feature/price_img1.png'
import priceImg2 from '../../../assets/images/feature/price_img2.png'
import priceCheck from '../../../assets/images/icons/price-check.png'
import sectionBg10 from '../../../assets/images/banner/section-bg10.png'
import sectionBg11 from '../../../assets/images/banner/section-bg11.png'

const Home4Price = () => {
    let [priceToggle, setPriceToggle] = useState(true)
    return (
        <>
            <section className='section-bg-shape2'>
                <div className="sc-price-style p-z-idex sc-pb-130 sc-md-pb-70">
                    <div className="container mx-auto max-width-95">
                        <div className="sc-heading-area sc-mb-40 sc-md-mb-30 text-center">
                            <h2 className="title sc-mb-25"><span className="gradiant-color">Transparent</span> Pricing</h2>
                        </div>
                        <div className="pricing-selector-two">
                            <form>
                                <button type="button" className="pricing-monthly-btn">Monthly</button>
                                <input type="checkbox" name="pricing-selector" id="pricing-selector" onChange={() => setPriceToggle(!priceToggle)} />
                                <button type="button" className="pricing-yearly-btn">Yearly <span>(35% Off)</span></button>
                            </form>
                        </div>
                        <div className={`${priceToggle ? 'pricing-monthly' : 'pricing-monthly d-none'}`}>
                            <div className="row">
                                <div className="col-3 mb-5">
                                    <Fade bottom delay={100}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">Free</h4>
                                                    <div className="sc-price">$0</div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> <b>100</b> Words</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1</b> Languages</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>10/m</b> Requests</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" to="/signup#"><span>Start</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-3 mb-5">
                                    <Fade bottom delay={100}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">Basic</h4>
                                                    <div className="sc-price">$14</div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> <b>10,000</b> Words</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1</b> Languages</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1,000/m</b> Requests</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" to="/signup#"><span>Start</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-3 mb-5">
                                    <Fade bottom delay={200}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg1} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">Business</h4>
                                                    <div className="sc-price">$29</div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> <b>50,000</b> Words</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>3</b> Languages</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1,000/m</b> Requests</li>
                                                    <li><img src={priceCheck} alt="Check" /> Segmentations</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-active" to="/signup#"><span>Start</span></Link>
                                            </div>
                                            <div className="price_sale">
                                                <span className="popular">popular</span>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-3 mb-5">
                                    <Fade bottom delay={100}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">Pro</h4>
                                                    <div className="sc-price">$79</div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> <b>200,000</b> Words</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>5</b> Languages</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1,000/m</b> Requests</li>
                                                    <li><img src={priceCheck} alt="Check" /> Segmentations</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" to="/signup#"><span>Start</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-3 mb-5">
                                    <Fade bottom delay={100}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">Advanced</h4>
                                                    <div className="sc-price">$299</div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> <b>1,000,000</b> Words</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>10</b> Languages</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1,000/m</b> Requests</li>
                                                    <li><img src={priceCheck} alt="Check" /> Segmentations</li>
                                                    <li><img src={priceCheck} alt="Check" /> 24/7 Support</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" to="/signup#"><span>Start</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-3 mb-5">
                                    <Fade bottom delay={100}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">Extended</h4>
                                                    <div className="sc-price">$699</div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> <b>5,000,000</b> Words</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>25</b> Languages</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1,000/m</b> Requests</li>
                                                    <li><img src={priceCheck} alt="Check" /> Segmentations</li>
                                                    <li><img src={priceCheck} alt="Check" /> 24/7 Support</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" to="/signup#"><span>Start</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-3 mb-5">
                                    <Fade bottom delay={100}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">Enterprise</h4>
                                                    <div className="sc-price">$999</div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> <b>10,000,000</b> Words</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>50+</b> Languages</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1,000/m</b> Requests</li>
                                                    <li><img src={priceCheck} alt="Check" /> Segmentations</li>
                                                    <li><img src={priceCheck} alt="Check" /> 24/7 Support</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" to="/signup#"><span>Start</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                        <div className={`${priceToggle ? 'pricing-yearly d-none' : 'pricing-yearly'}`}>
                            <div className="row">
                                <div className="col-3 mb-5">
                                    <Fade bottom delay={100}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">Free</h4>
                                                    <div className="sc-price">$0</div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> <b>100</b> Words</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1</b> Languages</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>10/m</b> Requests</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" to="/signup#"><span>Start</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-3 mb-5">
                                    <Fade bottom delay={100}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">Basic</h4>
                                                    <div className="sc-price">$149</div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> <b>10,000</b> Words</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1</b> Languages</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1,000/m</b> Requests</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" to="/signup#"><span>Start</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-3 mb-5">
                                    <Fade bottom delay={100}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">Business</h4>
                                                    <div className="sc-price">$289</div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> <b>50,000</b> Words</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>3</b> Languages</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1,000/m</b> Requests</li>
                                                    <li><img src={priceCheck} alt="Check" /> Segmentations</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" to="/signup#"><span>Start</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-3 mb-5">
                                    <Fade bottom delay={100}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">Pro</h4>
                                                    <div className="sc-price">$749</div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> <b>200,000</b> Words</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>5</b> Languages</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1,000/m</b> Requests</li>
                                                    <li><img src={priceCheck} alt="Check" /> Segmentations</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" to="/signup#"><span>Start</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-3 mb-5">
                                    <Fade bottom delay={100}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">Advanced</h4>
                                                    <div className="sc-price">$2,949</div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> <b>1,000,000</b> Words</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>10</b> Languages</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1,000/m</b> Requests</li>
                                                    <li><img src={priceCheck} alt="Check" /> Segmentations</li>
                                                    <li><img src={priceCheck} alt="Check" /> 24/7 Support</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" to="/signup#"><span>Start</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-3 mb-5">
                                    <Fade bottom delay={100}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">Extended</h4>
                                                    <div className="sc-price">$6,949</div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> <b>5,000,000</b> Words</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>25</b> Languages</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1,000/m</b> Requests</li>
                                                    <li><img src={priceCheck} alt="Check" /> Segmentations</li>
                                                    <li><img src={priceCheck} alt="Check" /> 24/7 Support</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" to="/signup#"><span>Start</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                                <div className="col-3 mb-5">
                                    <Fade bottom delay={100}>
                                        <div className="sc-price-box p-z-idex">
                                            <div className="price-box d-flex align-items-center">
                                                <div className="sc-price-image">
                                                    <img src={priceImg} alt="Images" />
                                                </div>
                                                <div className="price-item">
                                                    <h4 className="pric-title">Enterprise</h4>
                                                    <div className="sc-price">$9,949</div>
                                                </div>
                                            </div>
                                            <div className="price-list">
                                                <ul className="list">
                                                    <li><img src={priceCheck} alt="Check" /> <b>10,000,000</b> Words</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>50+</b> Languages</li>
                                                    <li><img src={priceCheck} alt="Check" /> <b>1,000/m</b> Requests</li>
                                                    <li><img src={priceCheck} alt="Check" /> Segmentations</li>
                                                    <li><img src={priceCheck} alt="Check" /> 24/7 Support</li>
                                                </ul>
                                            </div>
                                            <div className="sc-primary-btn">
                                                <Link className="sc-gradiant-btn" to="/signup#"><span>Start</span></Link>
                                            </div>
                                        </div>
                                    </Fade>
                                </div>
                            </div>
                        </div>
                        </div>
                </div>
                <div className="sc-section-bg2">
                    <img className="bg-image11" src={sectionBg10} alt="Bg" />
                    <img className="bg-image12" src={sectionBg11} alt="Bg" />
                </div>
            </section>
        </>
    );
};

export default Home4Price;
