import React from 'react';
const TermsDetails = () => {
    return (
        <>
            <section className="sc-feature-section-area5 sc-pt-175 sc-md-pt-115 sc-pb-140 sc-md-pb-70">
                <div className="container">
                    <div className="row align-items-center">
                       Use of Service: By using the GetGlossary.com API, you agree to abide by these terms and conditions. Our service is intended for lawful purposes only. You may not use our service to violate any applicable laws or regulations.
                       <br/><br/>
                       Intellectual Property: All content and materials included in our service, including but not limited to software, text, graphics, logos, images, and icons, are the property of our company or its affiliates and are protected by applicable copyright, trademark, and other intellectual property laws.
                       <br/><br/>
                       Privacy: We are committed to protecting your privacy. Our service collects certain data, such as user IP addresses, browser types, and referral pages, to analyze user behavior and improve our service. We may also collect personal information, such as name and email address, to provide customer support and communicate with you about our service. We will never sell or share your personal information with third parties without your consent, except as required by law.
                       <br/><br/>
                       Disclaimer of Warranties: Our service is provided on an "as is" and "as available" basis without warranties of any kind, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not warrant that our service will be uninterrupted or error-free, or that any defects will be corrected.
                       <br/><br/>
                       Limitation of Liability: In no event shall we be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use our service, even if we have been advised of the possibility of such damages.
                       <br/><br/>
                       Governing Law: These terms and conditions shall be governed by and construed in accordance with the laws of the state in which our company is incorporated. </div>
                </div>
            </section>
        </>
    );
};

export default TermsDetails;
