import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';
import whiteLogo from '../../assets/images/logo-dark.png'
import formulateLogo from '../../assets/images/formulate-logo.png'

const Footer3 = () => {
    return (
        <>
            <section className="sc-footer-section sc-pt-200 sc-md-pt-170 sc-sm-pt-100">
                <div className="container">
                    <div className="row padding-bottom">
                        <Fade fade delay={100}>
                            <div className="col-lg-3 col-sm-6">
                                <div className="footer-about">
                                    <div className="footer-logo sc-mb-25">
                                        <Link to="#"><img src={whiteLogo} alt="FooterLogo" /></Link>
                                    </div>
                                    <p className="footer-des">AI-Powered Localization - Using AI to Break Down Language Barriers</p>
                                    <p className="footer-des">Our AI-powered localization technology ensures that your content is accurately translated and culturally adapted, helping you to communicate with your target audience on a global scale.</p>
                                </div>
                            </div>
                        </Fade>
                        <Fade fade delay={200}>
                            <div className="col-lg-3 col-sm-6 sc-xs-mt-40">
                                <div className="footer-menu-area sc-pl-90 sc-lg-pl-0 sc-md-pl-0 sc-md-mb-30 sc-sm-mb-0">
                                    <h4 className="footer-title white-color sc-md-mb-15">Get Started</h4>
                                    <ul className="footer-menu-list">
                                        <li><Link to="/docs#">API Documentation</Link></li>
                                        <li><Link to="/pricing#">Pricing</Link></li>
                                        <li><Link to="/faq#">Guides & FAQ</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                        <Fade fade delay={300}>
                            <div className="col-lg-3 col-sm-6 sc-sm-mt-40">
                                <div className="footer-menu-area sc-pl-90 sc-lg-pl-0 sc-md-pl-0">
                                    <h4 className="footer-title white-color sc-md-mb-15">Resources</h4>
                                    <ul className="footer-menu-list">
                                        <li><a href="https://GetGlossary.com/privacy" target={'_blank'}>Privacy Policy</a></li>
                                        <li><a href="https://GetGlossary.com/terms" target={'_blank'}>Terms & Conditions</a></li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                        <Fade fade delay={400}>
                            <div className="col-lg-3 col-sm-6 sc-sm-mt-40">
                                <div className="footer-menu-area footer-menu-area-left sc-pl-65 sc-lg-pl-0 sc-md-pl-0">
                                    <h4 className="footer-title white-color sc-md-mb-15">Get In Touch</h4>
                                    <ul className="footer-menu-list">
                                        <li><i className="ri-mail-fill"></i><a href="mailto:hello@formulatelabs.com">hello@formulatelabs.com</a></li>
                                        <li><i className="ri-phone-fill"></i><a href="tel:18442624828">(844) 262 4828</a></li>
                                        <li className="footer-map"><i className="ri-map-pin-fill"></i><span>Florida, USA</span></li>
                                    </ul>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
                <div className="copyright-area">
                    <div className="container">
                        <div className="border-top"></div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="copyright-text text-center">
                                    <p>&copy; {new Date().getFullYear()} <Link to="#"> Formulate Labs, Inc.&nbsp;&nbsp;|&nbsp;&nbsp;</Link> All Rights Reserved.</p>
                                    <a href="https://FormulateLabs.com" target={'_blank'}><img style={{marginTop:10, height:50}} src={formulateLogo} alt="Formulate Logo" /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Footer3;
